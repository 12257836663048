import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectLink from "../components/ProjectLink"
import { Routing } from "../utils/routing"

const title = Routing.SECURITY.title

const Security = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={title} />
      <h1>{title}</h1>
      <p>
        <ProjectLink /> &nbsp; is constantly concerned about our member's
        critical information and privacy. With this in mind, we have the
        following security services installed on our Internet server:
      </p>
      <p>
        Each server is behind a Firewall. This UNIX based hardware product
        blocks critical ports and IP addresses on servers from external attack
        and access.
      </p>
      <p>
        Each server runs proprietary software that constantly monitors the
        servers for unauthorized use and attempts to "hack" into information.
        Administrators are contacted when forced attacks are committed, and
        countermeasures can be applied to stop these instances.
      </p>
      <p>
        All administrative activity requires user login and authentication. All
        administrative updates are logged into files that can be reviewed later.
      </p>
      <p>
        All servers come with a Global Verisign Certificate (RSA) for digitally
        encrypted communications between the Web server and you our member.
        Information passed in applications cannot be decrypted by third parties
        attempting to "pick" information being passed across the Internet
        backbone.
      </p>
      <p>
        All servers run the latest version of Apache Web Server, considered the
        strongest and most secure Web server software on the market.
      </p>
    </Layout>
  )
}

export default Security
